/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */
@import "_variables";

* {
    font-family: "Directa Serif";
}

// Fonts
@font-face {
    font-family: "Font Awesome 5 Free";
    src: url("~/assets/fonts/fa-solid-900.ttf");
}

@font-face {
    font-family: "Font Awesome 5 Regular";
    src: url("~/assets/fonts/fa-regular-400.ttf");
}

@font-face {
    font-family: "Font Awesome 5 Brands";
    src: url("~/assets/fonts/fa-brands-400.ttf");
}

@font-face {
    font-family: "Directa Serif";
    src: url("~/assets/fonts/Outras Fontes - DirectaSerif.otf");
}

.fa-solid {
    font-family: "Font Awesome 5 Free";
}

.fa-regular {
    font-family: "Font Awesome 5 Regular";
}

.fa-brands {
    font-family: "Font Awesome 5 Brands";
}

// Pointer to mark something as clickable
.clickable {
    cursor: pointer;
}

// Show invalid feedback message for forms
.ng-invalid.ng-touched + .invalid-feedback {
    display: block;
}

// Colors for alert messages
.alert {
    box-shadow: $box-shadow;
    color: $alert-font-color;
    font-weight: bold;

    &--danger {
        background-color: $danger-color;
    }

    &--success {
        background-color: $success-color;
    }

    &--info {
        background-color: $info-color;
    }

    &--warning {
        background-color: $warning-color;
    }
}

// Class for white card which is wrapped around every component.
.white-card {
    padding: 3em;
    background: white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
}

// Styles for the header label
.header-label {
    width: 100%;
    color: $accent-background-color-mid;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;

    &--bottom-separator {
        border-top: 3px solid $accent-background-color-light;
        opacity: 0.6;
    }
}

// Styles for the buttons
.btn {
    padding: 5px 30px;
    border-radius: $btn-border-radius;
    background-color: transparent;
    text-align: center;
    text-transform: uppercase;

    &--secondary {
        background-color: white;
        border: 1px solid $accent-background-color-mid;
        color: $accent-background-color-mid;
    }

    &--success {
        background-color: $accent-background-color;
        color: white;
    }

    &--disabled {
        cursor: not-allowed !important;
        opacity: 0.3;
    }

    &--danger {
        background-color: $warning-color;
        color: white;
    }
}

// Override cookieconsent's plugin classes
.cc-type-info {
    min-width: 90vw;
    right: 5vw;
    left: 5vw;
    top: 35vh;
    padding: 2em;

    .cc-compliance {
        display: block;
    }

    .cookie-banner-link--separator {
        color: $accent-font-color-mid !important;
        font-size: 1.3em;
        margin-top: 0.05em;
    }

    .cc-link {
        color: $accent-font-color-mid !important;
        font-weight: bold;
        text-decoration: none;
    }

    .cc-compliance {
        horizontal-align: center;
    }
}

// Edit icon for overviews.
.edit-icon,
.trash-icon {
    color: $font-color-dark;
}

// Add icon for overviews.
.add-icon {
    color: $success-background-color;
    font-size: 16pt;
    border: 0px;
    background: none !important;
}

// Accent font color.
.accent-font-color-mid {
    color: $accent-font-color-mid;
}

// Buttons.
.btn {
    &.btn-outlined {
        background-color: $background-color-light;
        color: $accent-font-color-mid;
        border: 1px solid $accent-font-color-mid;
    }

    &.btn-filled {
        background-color: $success-background-color;
        color: $font-color-light;
    }
}

// Dropdown Menu
.multiselect-dropdown {
    .multiselect-item-checkbox input[type="checkbox"] {
        + div {
            padding: 6px 10px !important;
        }

        + div:before {
            display: none;
        }

        + div:after {
            display: none;
        }
    }

    .multiselect-item-checkbox input[type="checkbox"]:checked {
        + div {
            background-color: $success-background-color !important;
            color: #fff !important;
        }
    }

    .selected-item {
        background-color: #fff !important;
        border-color: #fff !important;
        color: $font-color-dark !important;
        padding: 0px !important;
        margin: 0px !important;
        border: 0px !important;
        max-width: 100% !important
    }

    .dropdown-multiselect__caret:before {
        color: $success-background-color !important;
        border-color: $success-background-color transparent !important;
    }
}

// Pagination
.ngx-pagination {
    padding-left: 0;

    .current {
        background-color: #fff !important;
        color: $success-background-color !important;
        font-weight: bold !important;
    }

    .pagination-next {
        color: $success-background-color !important;
    }

    .pagination-previous {
        color: $success-background-color !important;
    }
}

// Link
.link {
    color: $alert-font-color;
    text-decoration: underline;
}

// main-content has to be used for backbone layout (see as well media queries).
.main-content {
    margin-top: $primary-header-height + $secondary-header-height;
    min-height: calc(100vh - #{$primary-header-height} - #{$footer-height} - #{$secondary-header-height});
}

// THW classes

.thw-logo {
    height: 50px;
}

@media (max-width: 992px) {
    .main-content {
        margin-top: $primary-header-height-collapsed + $secondary-header-height-collapsed;
        min-height: calc(
            100vh - #{$primary-header-height-collapsed} - #{$secondary-header-height-collapsed} - #{$footer-height}
        );
    }
}

@media (max-width: 767px) {
    .main-content {
        min-height: calc(
            100vh - #{$primary-header-height-collapsed} - #{$secondary-header-height-collapsed} - #{$footer-height-mobile}
        );
    }
}

@media (max-width: 575px) {
    h2 {
        font-size: 16pt;
    }

    .btn {
        font-size: 10pt;
    }

    h1 {
        font-size: 22pt;
    }
}
